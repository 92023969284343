export const distributionItems = [
    {
        id: 0,
        title: 'WhatsApp-рассылка',
        titleEn: 'WhatsApp campaign',
        format: 'формат: текст',
        formatEn: 'format: text',
        priceText: 'от 1 руб. за единицу',
        price: 1,
        desc: [
            'Текст рассылки WhatsApp до 1000 символов, что почти в 15 раз больше, чем в SMS',
            'Возможность использовать эмодзи и картинку в рассылке',
            'Бесплатная проверка базы номеров на наличие WhatsApp',
            'Единая цена на рассылку на любую страну мира',
            'Детализированная отчетность',
            'Возможность использования переменных в рассылке',
            'Возможность интеграции с любыми сервисами по API',
            'Удобный и понятный личный кабинет'
        ],
        descEn: [
            'WhatsApp newsletter text up to 1000 characters, which is almost 15 times more than SMS',
            'Ability to use emojis and pictures in the newsletter',
            'Free check of number database for WhatsApp presence',
            'Single price for mailing to any country in the world',
            'Detailed reporting',
            'Ability to use variables in mailings',
            'Possibility of integration with any services via API',
            'Convenient and clear personal account'
        ],
        priceItems: [
            {
                min: 1000,
                max: 5000,
                price: 3.5,
                priceUSD: 0.05
            },
            {
                min: 5001,
                max: 20000,
                price: 3.3,
                priceUSD: 0.045
            },
            {
                min: 20001,
                max: 100000,
                price: 3.0,
                priceUSD: 0.04
            }
        ]
    },
    {
        id: 1,
        title: 'Telegram-рассылка',
        titleEn: 'Telegram campaign',
        format: 'формат: картинка текст',
        formatEn: 'format: picture text',
        priceText: 'от 2 руб. за единицу',
        price: 2,
        desc: [
            'Текст рассылки Telegram до 1000 символов, что почти в 15 раз больше, чем в SMS',
            'Кликабельная ссылка',
            'Возможность использовать любые медиафайлы в рассылке (Видео, Кружочки, аудио, подставить аватар и имя аккаунту и т. п.)',
            'Возможность проверки базы номеров на наличие Telegram',
            'Единая цена на рассылку на любую страну мира',
            'Детализированная отчетность',
            'Бесплатный Парсинг аудитории'
        ],
        descEn: [
            'Telegram newsletter text up to 1000 characters, which is almost 15 times more than in SMS',
            'Clickable link',
            'Ability to use any media files in the newsletter (Video, Circles, audio, substitute an avatar and account name, etc.)',
            'Ability to check the number database for the presence of Telegram',
            'Single price for mailing to any country in the world',
            'Detailed reporting',
            'Free Audience Parsing'
        ],
        priceItems: [
            {
                min: 1000,
                max: 5000,
                price: 3.5,
                priceUSD: 0.038
            },
            {
                min: 5001,
                max: 20000,
                price: 3.2,
                priceUSD: 0.035
            },
            {
                min: 20001,
                max: 100000,
                price: 3.0,
                priceUSD: 0.032
            }
        ]
    },
    {
        id: 2,
        title: 'SMS-рассылка',
        titleEn: 'SMS campaign',
        format: 'формат: текст контакты ссылка',
        formatEn: 'format: text contacts link',
        priceText: 'от 3 руб. за единицу',
        price: 3,
        desc: [
            'Возможность оповестить всю базу абонентов',
            'Кликабельная ссылка',
            'Возможность использовать в тексте эмодзи',
            'Детализированная отчетность',
            'Возможность интеграции с любыми сервисами по API',
            'Удобный и понятный личный кабинет'
        ],
        descEn: [
            'Possibility to notify the entire subscriber base',
            'Clickable link',
            'Ability to use emojis in text',
            'Detailed reporting',
            'Possibility of integration with any services via API',
            'Convenient and clear personal account'
        ],
        priceItems: [
            {
                min: 1000,
                max: 5000,
                price: 3.5,
                priceUSD: 0.038
            },
            {
                min: 5001,
                max: 20000,
                price: 3.0,
                priceUSD: 0.035
            },
            {
                min: 20001,
                max: 100000,
                price: 2.0,
                priceUSD: 0.032
            }
        ]
    },
    {
        id: 3,
        title: 'Viber-рассылка',
        titleEn: 'Viber campaign',
        format: 'формат: текст',
        formatEn: 'format: text',
        priceText: 'от 4 руб. за единицу',
        price: 4,
        desc: [
            'Текст рассылки Viber до 1000 символов, что почти в 15 раз больше, чем в SMS',
            'Кликабельная ссылка',
            'Возможность использовать эмодзи и картинку в рассылке',
            'Бесплатная проверка базы номеров на наличие Viber',
            'Единая цена на рассылку на любую страну мира',
            'Детализированная отчетность',
            'Возможность использования переменных в рассылке',
            'Возможность интеграции с любыми сервисами по API',
            'Удобный и понятный личный кабинет'
        ],
        descEn: [
            'Viber newsletter text up to 1000 characters, which is almost 15 times more than SMS',
            'Clickable link',
            'Ability to use emojis and pictures in the newsletter',
            'Free number database check for Viber availability',
            'Single price for mailing to any country in the world',
            'Detailed reporting',
            'Ability to use variables in mailings',
            'Possibility of integration with any services via API',
            'Convenient and clear personal account'
        ],
        priceItems: [
            {
                min: 10000,
                max: 20000,
                price: 2.5,
                priceUSD: 0.02
            },
            {
                min: 20001,
                max: 50000,
                price: 2.3,
                priceUSD: 0.015
            },
            {
                min: 50001,
                max: 100000,
                price: 2.1,
                priceUSD: 0.011
            }
        ]
    },
];
