import React from "react";
import "../../App.css";
import { useTranslation } from 'react-i18next';
import {ReactComponent as Mail} from "../../icons/icons8-mail.svg";
import {ReactComponent as Phone} from "../../icons/phone.svg";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div id="contact">
        <div className="container contact-bottom">
          <div className="contact-info ">
            <div className="contactitem">
                <h3>
                  {t('contaсtInfo')}
                </h3>
            </div>
            <div className="contact-item">
              <p className="contactitem">
                <span>
                  {t('address')}
                </span>
                {t('addressInfo')}
              </p>
            </div>
            <div className="contact-item">
              <p className="contactitem">
                <span>
                  {t('phone')}
                </span>{" "}
                <a href="tel:88005554947" target="_blank" rel="noreferrer" className="contactlink">{t('phoneInfo')}</a>
              </p>
            </div>
            <div className="contact-item">
              <p className="contactitem">
                <span>
                  {t('email')}
                </span>{" "}
                <a href="mailto:info@sms-retail.ru" target="_blank" rel="noreferrer" className="contactlink">{t('emailInfo')}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2014-2025 SMS Retail
          </p>
        </div>
      </div>
    </div>
  );
};
