import React from "react";
import { distributionItems } from "../../data/distributionItems";
import { ReactComponent as Mail } from '../../icons/icons8-mail.svg';
import { ReactComponent as Telegram } from '../../icons/icons8-telegram.svg';
import { ReactComponent as Whatsapp } from '../../icons/icons8-whatsapp.svg';
import { useTranslation } from 'react-i18next';

import "./Services.css";

export const Services = () => {
    const [currentPriceItem, setCurrentPriceItem] = React.useState(distributionItems[0]);
    const { t } = useTranslation();
    const els = [
        {
            id: 0,
            el: <Whatsapp className={'servicesIcon'}/>,
            ruPic: '/img/phonescreen/whatsapp_ru.png',
            altText: 'WhatsApp'
        },
        {
            id: 1,
            el: <Telegram className={'servicesIcon'}/>,
            ruPic: '/img/phonescreen/telegram_ru.png',
            altText: 'Telegram'
        },
        {
            id: 2,
            el: <Mail className={'servicesIcon'}/>,
            ruPic: '/img/phonescreen/sms_ru.png',
            altText: 'SMS'
        },
        {
            id: 3,
            el: <Mail className={'servicesIcon'}/>,
            ruPic: '/img/phonescreen/viber_ru.png',
            altText: 'Viber'
        }
    ]

    return (
        <div className={'servicePage'}>
            <div className={'pricesItems'}>
                {distributionItems.map(item => <div className={currentPriceItem.id === item.id ? 'selectedPriceItem' : 'priceItem'} onClick={() => {setCurrentPriceItem(item)}}>{item[t("prices.titleField")]}</div>)}
            </div>
            {/*<div>{t('services.texts', { returnObjects: true })[1]}</div>*/}
            <div className={'currentservicecontainer'}>
                <div className={'serviceItemHeader1'}>{currentPriceItem[t("prices.titleField")]}</div>
                <div className={'servicephonepicturecontainer'}><img src={els[currentPriceItem.id].ruPic} alt={els[currentPriceItem.id].altText} className={'servicephonepicture'}/></div>
                <div className={'servicesInfo'}>
                    <div className={'serviceItemHeader2'}>{currentPriceItem[t("prices.titleField")]}</div>
                    {currentPriceItem[t("services.descField")].map(item => <div className={'servicesItem'}>{els[currentPriceItem.id].el}{item}</div>)}
                </div>
            </div>
        </div>
    );
};