import React from "react";
import { Range } from 'react-range';
import { distributionItems } from "../../data/distributionItems";
import "./Prices.css";
import {useTranslation} from "react-i18next";
import i18n from "../../texts/i18n";

const countries = ['Brazil', 'UAE', 'India', 'USA'];

export const Prices = (props) => {
    const [currentPriceItem, setCurrentPriceItem] = React.useState(distributionItems[0]);
    const [currentCountryItem, setCurrentCountryItem] = React.useState(countries[3]);
    const [values, setValues] = React.useState([0]);
    const { t } = useTranslation();
    const isInternationalVersion = i18n.options.fallbackLng.length === 1;
    // const isInternationalVersion = true;
    const fieldForShowedPrice = isInternationalVersion ? 'priceUSD' : 'price';
    const showedPriceOfOneItem = values[0] <= currentPriceItem.priceItems[0].max ?
        currentPriceItem.priceItems[0][fieldForShowedPrice] :
        (values[0] <= currentPriceItem.priceItems[1].max ? currentPriceItem.priceItems[1][fieldForShowedPrice] : currentPriceItem.priceItems[2][fieldForShowedPrice]);
    const showedPrice = Math.round(values[0] * showedPriceOfOneItem);
    return (
        <div className={'pricePage'}>
            <div className={'pricesItems'}>
                {distributionItems.map(item => <div className={currentPriceItem.id === item.id ? 'selectedPriceItem' : 'priceItem'} onClick={() => {setCurrentPriceItem(item); setValues([0])}}>{item[t("prices.titleField")]}</div>)}
            </div>
            {
                currentPriceItem.id === 2 && isInternationalVersion &&
                <div className={'countryItems'}>
                    {countries.map(item => <div className={currentCountryItem === item ? 'selectedPriceItem' : 'priceItem'} onClick={() => {setCurrentCountryItem(item)}}>{item}</div>)}
                </div>
            }

            <div>
            <div className={'priceText'}>{currentPriceItem[t("prices.titleField")]}{currentPriceItem.id === 2 && isInternationalVersion ? ` (${currentCountryItem})` : ''}</div>
            <div className={'priceFormat'}>{currentPriceItem[t("prices.formatField")]}</div>
                <div className="ptable">
                    <div className="prow">
                        <div className="pcell pcellleft">{t('prices.amount')}</div>
                        {currentPriceItem.priceItems.map(item => <div className="pcell pcellhead">{item.min} - {item.max}</div>)}
                    </div>
                    <div className="prow">
                        <div className="pcellprice pcell pcellleft">{isInternationalVersion ? t('prices.priceUSD') : t('prices.price')}</div>
                        {currentPriceItem.priceItems.map(item => <div className="pcell pcellmain">{isInternationalVersion ? item.priceUSD : item.price}</div>)}
                    </div>
                </div>
                {values[0] === 0 ? (
                    <div className={'priceInfo'}><div className={'priceInfoEmpty'}>{t('prices.emptyText')}</div></div>
                ) : (
                    <div className={'priceInfo'}>
                        <div className={'priceInfoItem'}>{values} {t('prices.pcs')}</div>
                        <div className={'priceInfoItem'}>{t('prices.priceDots')} {showedPrice} {isInternationalVersion ? t('prices.dollars') : t('prices.rubles')}</div>
                    </div>
                )}
            <div className={'priceRange'}>
                <Range
                    step={1000}
                    min={0}
                    max={currentPriceItem.priceItems[2].max}
                    values={values}
                    onChange={(values) => setValues(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                backgroundColor: '#337ab7'
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            className={'thumb'}
                            {...props}
                            style={{
                                ...props.style,
                                height: '42px',
                                width: '42px',
                                backgroundColor: 'transparent',
                                border: 'none',
                            }}
                        />
                    )}
                />
            </div>
                <div className={"priceMarks"}>
                    <div>0</div>
                    <div>{currentPriceItem.priceItems[2].max}</div>
                </div>
            </div>
        </div>
    );
};