import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import SmoothScroll from "smooth-scroll";
import { About, Blog, Layout, Main, Prices, Services } from "./pages";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
      <Routes>
          <Route path="/" element={<Layout />}>
              <Route index element={<Main />} />
              <Route path="services" element={<Services />} />
              <Route path="prices" element={<Prices />} />
              <Route path="blog" element={<Blog />} />
              <Route path="about" element={<About />}/>
              <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
      </Routes>
  );
};

export default App;
