import React, {useState} from "react";
import emailjs from "emailjs-com";
import { useTranslation } from 'react-i18next';
import "../../App.css";

const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Main = (props) => {
    const [{ name, email, message }, setState] = useState(initialState);
    const [isSentError, setSentError] = useState(false);
    const [isSent, setSent] = useState(false);
    const { t } = useTranslation();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // LOCAL
            // const response = await fetch("http://localhost:5000/api/email/send", {
            // SERVER
            const response = await fetch("/api/email/send", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({phone: name, email}),
            });
            const data = await response;
            setSent(true);
            setSentError(data.status !== 200);
        } catch (error) {
            setSent(true);
            setSentError(true);
            console.error("Ошибка отправки:", error);
        }
    };
    return (
        <header id="header">
            <div className="intro">
                <div className="overlay mainPage">
                    <div className="intro-text">
                        <h2>
                            {t('titletitle')}
                            <span></span>
                        </h2>
                        <p className="mainRow">{t('titledesc1')}</p>
                        <p className="mainRow">{t('titledesc2')}</p>
                        <p className="mainRow">{t('titledesc3')}</p>
                    </div>

                    <div className="form">
                        <div>
                            <div className="section-title">
                                <h2>{t('formtitle')}</h2>
                                <p>{t('formdesc')}</p>
                            </div>
                            <form name="sentMessage" onSubmit={handleSubmit}>
                                <div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="tel"
                                                id="name"
                                                name="name"
                                                className="form-input form-control"
                                                placeholder={t('formplaceholder1')}
                                                pattern="^\+?[1-9]\d{1,25}$"                                                required
                                                onChange={handleChange}
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-input form-control"
                                                placeholder={t('formplaceholder2')}
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                </div>
                                <div id="success"></div>
                                {
                                    isSent ?
                                        isSentError ?
                                            <div className="sent-text">{t('formSentError')}</div>
                                            :
                                         <div className="sent-text">{t('formSentSuccess')}</div>
                                        :
                                        <button type="submit" className="send-button btn btn-custom btn-lg">
                                            {t('formbutton')}
                                        </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};