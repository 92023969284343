import React from "react";
import { useTranslation } from 'react-i18next';
import "../../App.css";


const aboutItems = [
    {
        year: '2014 год.',
        yearEn: '2014',
        desc: 'Год создания компании. Начинаем деятельность с ресселерства услуг sms - рекламы.',
        descEn: 'Year the company was founded. We are starting to reseller sms advertising services.'
    },
    {
        year: '2016 год.',
        yearEn: '2016',
        desc: 'Создание собственной платформы по sms - рассылкам. Первые прямые контракты с операторами связи.',
        descEn: 'Creating your own platform for SMS mailings. The first direct contracts with telecom operators.'
    },
    {
        year: '2017 год.',
        yearEn: '2017',
        desc: 'Открытие собственного производства. Создание собственной sms - фермы для  рассылок. Начало пути работы в альтернативных каналах sms рекламы.',
        descEn: 'Opening your own production. Creating your own SMS farm for mailings. The beginning of the journey of working in alternative SMS advertising channels.'
    },
    {
        year: '2018 год.',
        yearEn: '2018',
        desc: 'Создание собственного сервиса по рассылкам WhatsApp.',
        descEn: 'Creating your own WhatsApp messaging service.'
    },
    {
        year: '2020 год.',
        yearEn: '2020',
        desc: 'Создание собственного сервиса по рассылке Viber.',
        descEn: 'Creating your own Viber mailing service.'
    },
    {
        year: '2021 год.',
        yearEn: '2021',
        desc: 'Создание собственного сервиса sms активаций. Создание собственного сервиса по рассылке Telegram',
        descEn: 'Creating your own SMS activation service. Creating your own Telegram mailing service'
    },
    {
        year: '2023 год.',
        yearEn: '2023',
        desc: 'Создание собственного сервиса по рассылке IMO',
        descEn: 'Creating your own IMO mailing service'
    },
    {
        year: '2024 год.',
        yearEn: '2024',
        desc: 'Создание API для рассылок во всех мессенджерах.',
        descEn: 'Creation of an API for mailings in all instant messengers.'
    },
    {
        year: 'По состоянию на 2024 год компания Sms - Retail абсолютный лидер по объему sms трафика и трафика в мессенджерах в России и СНГ, по альтернативным каналам рассылок. Пройден путь от ресселерства до собственного производства абсолютно всех продуктов. Сотрудничая с нашей компанией вы получите безупречный сервис, лучшее соотношение цены и качества предоставляемых услуг, полное соблюдение NDA и сохранности баз.',
        yearEn: 'As of 2024, the Sms - Retail company is the absolute leader in the volume of SMS traffic and traffic in instant messengers in Russia and the CIS, via alternative mailing channels. We have come a long way from resellers to our own production of absolutely all products. By collaborating with our company, you will receive impeccable service, the best ratio of price and quality of services provided, full compliance with NDA and database security.'
    }
]

const aboutHeader = {
    header: 'О Нас',
    headerEn: 'About'
}

export const About = () => {
    const { t } = useTranslation();
    return (
        <div className={'aboutPage'}>
            <div className={'aboutH'}>{aboutHeader[t("about.headerText")]}</div>
            <div className={'aboutInfo'}>
                {
                    aboutItems.map(item => (
                        <div className={'aboutItem'}>
                            <div className={'aboutYear'}>{item[t("about.yearField")]}</div>
                            <div className={'aboutText'}>{item[t("about.descField")]}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};
