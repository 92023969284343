import en from './en.json'
import ru from './ru.json'

import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

const resources = {
    en: {
        translation: en,
    },
    ru:{
        translation:ru,
    }
}
const domain = window.location.hostname;
const languageDefault = domain === 'sms-retail.io' ? 'en' : JSON.parse(localStorage.getItem('language'));
const languages = domain === 'sms-retail.io' ? ['en'] : ['ru', 'en'];

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: languageDefault,
        fallbackLng: languages
    })

export default i18n;