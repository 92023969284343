import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import "../../App.css";
import { ReactComponent as Chevron } from '../../icons/chevron.svg';


const faq = [
    {
        id: 0,
        question: 'В чем принципиальное отличие Вашей компании от других смс-агрегаторов?',
        answer: 'В отличие от других подобных компаний, мы являемся разработчиками и продаем собственные услуги. А не занимаемся ресейлом чужих услуг',
        questionEn: 'What is the fundamental difference between your company and other SMS aggregators?',
        answerEn: 'Unlike other similar companies, we are developers and sell our own services. We don’t resell other people’s services'
    },
    {
        id: 1,
        question: 'Как давно Ваша компания на рынке?',
        answer: 'В 2024 году нашей компании исполняется 10 лет. И мы прошли путь от обычного реселлера до собственного отдела разработки по всем продуктам',
        questionEn: 'How long has your company been on the market?',
        answerEn: 'In 2024, our company turns 10 years old. And we have gone from a regular reseller to our own development department for all products'
    },
    {
        id: 2,
        question: 'Что такое альтернативные каналы? Почему Ваши цены на рассылку сильно дешевле других компаний?',
        answer: 'Мы выбрали для себя путь создания аналогов официальным каналам. Без потери качества и более широкими возможностями для клиентов',
        questionEn: 'What are alternative channels? Why are your prices for newsletters much cheaper than other companies?',
        answerEn: 'We have chosen for ourselves the path of creating analogues to official channels. Without loss of quality and greater opportunities for customers'
    },
    {
        id: 3,
        question: 'В чем преимущества альтернативных каналов?',
        answer: 'Преимуществ множество. Это гораздо более доступная цена, это возможность рассылок без согласований множества инстанций своего имени, тематики. Возможность рассылки на Гемблинг, Беттинг, Алкоголь и т.п. Все то, что не получится рассылать в официальных каналах',
        questionEn: 'What are the advantages of alternative channels?',
        answerEn: 'There are many benefits. This is a much more affordable price, it is an opportunity to send out mailings without the approval of many authorities of your name and subject matter. Possibility of sending to Gambling, Betting, Alcohol, etc. Everything that cannot be sent through official channels'
    },
    {
        id: 4,
        question: 'То есть Вы берете в рассылку абсолютно все тематики, которые возможно?',
        answer: 'Нет, категорически нет. Мы работаем в правовом поле по законам Российской Федерации и не берем в работу никакие тематики, нарушающие законы Российской Федерации',
        questionEn: 'That is, you include absolutely all topics that are possible in your mailing list?',
        answerEn: 'No, absolutely not. We work within the legal framework according to the laws of the Russian Federation and do not take on any topics that violate the laws of the Russian Federation'
    },
    {
        id: 5,
        question: 'Ну в чем-то же есть недостаток таких каналов?',
        answer: 'Недостаток альтернативных каналов безусловно есть. И основной недостаток - это лимитированный объем сообщений. Но наша команда день ото дня развивает все наши продукты так, чтобы клиент все меньше чувствовал, что каналы имеют лимит',
        questionEn: 'Well, what is the disadvantage of such channels?',
        answerEn: 'There is certainly a lack of alternative channels. And the main disadvantage is the limited volume of messages. But our team is developing all our products day by day so that the client feels less and less that channels have a limit'
    },
    {
        id: 6,
        question: 'Что делать, если хочу с Вами работать, но у меня нет своей базы номеров?',
        answer: 'Конечно мы рекомендуем клиентам самим решить вопрос с базой. Но также у нас есть понимание, в каких источниках искать базу клиенту и мы можем Вас направить в нужное направление. Чтобы Вы обзавелись базой и мы начали сотрудничество',
        questionEn: 'What should I do if I want to work with you, but I don’t have my own database of numbers?',
        answerEn: 'Of course, we recommend that clients resolve the issue with the database themselves. But we also have an understanding of what sources the client should look for and we can point you in the right direction. So that you get a base and we start cooperation'
    },
    {
        id: 7,
        question: 'Я хочу сделать рассылку по мессенджерам, но я не уверен, что все мои получатели имеют установленный WhatsApp на своем номере телефона?',
        answer: 'Мы делаем валидацию базы на наличие нужного Вам мессенджера абсолютно бесплатно. И Вы сможете отдать в рассылку только ту базу, где установлен мессенджер на каждый номер абонента',
        questionEn: 'I want to send messages via instant messengers, but I\'m not sure if all my recipients have WhatsApp installed on their phone number?',
        answerEn: 'We validate the database for the presence of the messenger you need absolutely free. And you will be able to send to the mailing list only the database where the messenger is installed for each subscriber number'
    }
];

function textToSentences(text) {
    // Разделяем текст на части по любым знакам окончания предложений
    const rawSentences = text.split(/(?<=[.!?])\s+/);

    // Обрабатываем каждую часть, чтобы:
    // - Удалить лишние пробелы в начале и в конце
    // - Начинать с большой буквы
    // - Заканчиваться точкой
    const formattedSentences = rawSentences
        .map(sentence => {
            sentence = sentence.trim();

            // Игнорируем пустые строки
            if (sentence.length === 0) return null;

            // Начинаем с заглавной буквы
            sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);

            // Добавляем точку, если её нет
            if (!/[.!?]$/.test(sentence)) {
                sentence += ".";
            }

            return sentence;
        })
        .filter(Boolean); // Убираем null, которые могли появиться из-за пустых строк

    return formattedSentences;
}

export const Blog = () => {
    const [openItems, setOpenItems] = useState([
        {id: 0, open: false},
        {id: 1, open: false},
        {id: 2, open: false},
        {id: 3, open: false},
        {id: 4, open: false},
        {id: 5, open: false},
        {id: 6, open: false},
        {id: 7, open: false}
    ]);
    const { t } = useTranslation();
    const handleQuestionLineClick = (id) => () => {
        const newOpenItems = openItems.map(item => {
            if(item.id === id) {
               return {...item, open: !item.open};
            }
            return {...item};
        })
        setOpenItems(newOpenItems);
    };
    return (
        <div className={'faqPage'}>
            <div className={'faqText'}>FAQ</div>
            <div className={'faqInfo'}>
                {faq.map(item => <div className={'faqItem'} onClick={handleQuestionLineClick(item.id)}>
                    <div className={'faqQuestionLine'}>
                        <div>{item[t("blog.questionField")]}</div>
                        <div><Chevron className={openItems[item.id].open ? 'chevronRotate' : 'chevron'}/></div>
                    </div>
                    {openItems[item.id].open && <div className={'faqAnswerLine'}>{textToSentences(item[t("blog.answerField")]).map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}</div>}
                </div>)}
            </div>
        </div>
    );
};